.canvas {
  display: flex;
  overflow: scroll;
  width: 100%;
  height: 100%;
  cursor: grab;

  &_grabbed {
    cursor: grabbing;
    user-select: none;
  }
}
