// Breakpoints
$mobile-breakpoint: 764px;

// Colors
$dark-grey-color: #333;
$grey-color: #777;
$lighter-grey-color: #999;
$light-grey-color: #eee;

$green-color: #75bf1e;
$blue-color: #0071b9;

// Animation
$animation-duration: 0.3s;
$animation-timing-function: ease;

// Font
$font-family: 'Roboto', sans-serif;

$font-size-m: 14px;
$font-size-s: 12px;
$font-size-xs: 10px;

// Shadow
$shadow: 0 0 21px rgba(0, 0, 0, 0.15);

// Sizes
$member-picture-width: 80px;
$member-picture-height: 100px;
