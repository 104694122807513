@import 'ui-variables';

@mixin transition($transitions...) {
  $unfolded-transitions: ();
  @each $transition in $transitions {
    $unfolded-transitions: append($unfolded-transitions, $transition $animation-duration $animation-timing-function, comma);
  }

  transition: $unfolded-transitions;
}

@mixin for-mobile() {
  @media (max-width: $mobile-breakpoint) {
    @content;
  }
}
