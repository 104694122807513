@import './src/config/style/ui-variables';

.modal {
  position: relative;
  overflow: hidden;
  width: 350px;
  max-width: 80%;
  min-height: 80px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: $shadow;
  text-align: center;

  &__answer-container {
    display: flex;
    margin-bottom: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid $light-grey-color;
  }

  &__answer-input {
    flex-grow: 1;
    box-sizing: border-box;
    padding: 5px;
    border: 1px solid $lighter-grey-color;
    border-radius: 5px 0 0 5px;
    font-size: $font-size-s;

    &:focus {
      outline: none;
    }
  }

  &__button {
    border: 1px solid $lighter-grey-color;
    border-left: 0;
    border-radius: 0 5px 5px 0;

    &:focus {
      outline: none;
    }
  }

  &__title {
    margin-bottom: 10px;
    font-weight: 700;
  }

  &__description {
    font-size: $font-size-s;
  }

  &__continue-button {
    margin-top: 15px;
    cursor: pointer;
  }
}
