@import './src/config/style/ui-variables';
@import './src/config/style/mixins';

.general-info {
  $this: &;

  position: relative;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  align-items: center;
  text-align: center;

  @include for-mobile {
    flex-shrink: 1;
  }

  &__local-indicator {
    position: absolute;
    top: 80px;
    left: 50%;
    display: block;
    width: 8px;
    height: 8px;
    margin-left: -$member-picture-width/2 + 12px;
    border: 3px solid #fff;
    border-radius: 50%;
    background: $green-color;
    transform: translateX(-50%);
    @include transition(width, height, margin, top, border-width);

    #{$this}_collapsed & {
      top: 0;
      width: 0;
      height: 0;
      margin: 0;
      border-width: 0;
    }
  }

  &__international-indicator {
    position: absolute;
    right: 50%;
    display: block;
    width: 12px;
    height: 12px;
    margin-right: -$member-picture-width/2 - 4px;
    border: 3px solid #fff;
    border-radius: 50%;
    background-color: #fff;
    background-image: url('./images/world.svg');
    background-position: center;
    background-size: contain;
    transform: translateX(-50%);
    @include transition(width, height, margin, border-width);

    #{$this}_collapsed & {
      width: 0;
      height: 0;
      margin: 0;
      border-width: 0;
    }
  }

  &__name {
    margin-bottom: 4px;
    font-weight: 700;
    font-size: $font-size-m;
    line-height: 1.2;
    letter-spacing: 0.5px;
    text-transform: capitalize;
    @include transition(font-size, letter-spacing, color, margin);

    #{$this}_collapsed & {
      margin: 0;
      font-size: 0;
      letter-spacing: 0;
    }

    #{$this}_highlighted & {
      color: $blue-color;
    }
  }

  &__status {
    color: $grey-color;
    font-size: $font-size-s;
    text-transform: capitalize;
    @include transition(font-size, color, font-weight);

    #{$this}_collapsed & {
      font-size: 0;
    }

    #{$this}_highlighted & {
      color: $blue-color;
      font-weight: 700;
    }
  }
}
