@import './src/config/style/ui-variables';
@import './src/config/style/mixins';

.notification {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  padding: 20px;
  border-radius: 10px;
  background: #fff;
  box-shadow: $shadow;
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
  @include transition(opacity, transform, visibility, margin);

  &:not(&:last-child) {
    margin-bottom: 20px;
  }

  &_hidden {
    margin: 0;
    opacity: 0;
    visibility: hidden;
    transform: translateY(30px);
  }

  &__member-link {
    color: $blue-color;
    font-weight: bold;
    white-space: nowrap;
    cursor: pointer;
  }

  &__close-button {
    position: absolute;
    top: -5px;
    right: -5px;
  }
}
