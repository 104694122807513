.loader {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  background: #fff;

  &__logo {
    $size: 100px;

    @keyframes rotate {
      0% { transform: rotate(0) }
      100% { transform: rotate(360deg) }
    }

    position: absolute;
    top: 50%;
    left: 50%;
    width: $size;
    height: $size;
    margin-top: -$size/2;
    margin-left: -$size/2;
    animation: rotate 2s linear infinite;

    &_s {
      $size: 50px;

      width: $size;
      height: $size;
      margin-top: -$size/2;
      margin-left: -$size/2;
    }
  }
}
