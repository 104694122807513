@import './src/config/style/ui-variables';
@import './src/config/style/mixins';

.result {
  position: relative;
  display: block;
  width: 100%;
  padding: 5px 10px;
  border: 0;
  background: #fff;
  text-align: left;
  cursor: pointer;
  @include transition(background);

  &:hover,
  &:focus {
    background-color: $light-grey-color;
  }

  &:first-child {
    padding-top: 10px;
  }

  &:last-child {
    padding-bottom: 10px;
  }

  &__name {
    font-size: $font-size-m;
  }

  &__bold {
    color: $blue-color;
    font-weight: 700;
  }

  &__status {
    margin-left: 5px;
    color: $grey-color;
    font-size: $font-size-xs;
  }
}
