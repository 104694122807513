@import './src/config/style/ui-variables';
@import './src/config/style/mixins';

.close-button {
  position: relative;
  overflow: hidden;
  width: 18px;
  height: 18px;
  padding: 0;
  border: 0;
  border-radius: 50%;
  background: $lighter-grey-color;
  color: #fff;
  font-size: $font-size-xs;
  line-height: 20px;
  text-align: center;
  cursor: pointer;
}
