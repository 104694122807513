@import-normalize;
@import './src/config/style/ui-variables';

html {
  min-width: 320px;
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  color: $dark-grey-color;
  font-size: $font-size-m;
  font-family: $font-family;
  line-height: 1.5;
}

.root {
  height: 100%;
}

a {
  color: $blue-color;
  text-decoration: none;
}
