@import './src/config/style/ui-variables';
@import './src/config/style/mixins';

.member {
  position: relative;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-right: 1px solid $light-grey-color;
  @include transition(padding, opacity);

  &:last-child {
    border: 0;
  }

  &_has-parent {
    $padding-top: 20px;

    padding: $padding-top 0 0;
    border: 0;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      width: 100%;
      height: 1px;
      background: $lighter-grey-color;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      width: 1px;
      height: $padding-top;
      background: $lighter-grey-color;
      @include transition(height);
    }

    &:first-child::before {
      left: 50%;
      width: 50%;
    }

    &:last-child::before {
      left: 0;
      width: 50%;
    }

    &:only-child::before {
      display: none;
    }
  }

  &_collapsed {
    padding: 0;
    opacity: 0;

    &::before,
    &::after {
      height: 0;
    }
  }

  &__children {
    position: relative;
    display: flex;
    justify-content: center;
  }
}
