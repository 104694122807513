@import './src/config/style/mixins';

.notifications-container {
  position: fixed;
  right: 40px;
  bottom: 40px;
  z-index: 9;
  width: 420px;

  @include for-mobile {
    right: 20px;
    bottom: 20px;
    width: 100%;
    max-width: calc(100vw - 40px);
  }
}
