@import './src/config/style/ui-variables';
@import './src/config/style/mixins';

.toggle-button {
  $margin-bottom: 20px;

  position: relative;
  width: 16px;
  height: 16px;
  margin-top: 10px;
  padding: 0;
  border: 0;
  border-radius: 50%;
  background: $blue-color;
  color: #fff;
  font-weight: 500;
  font-size: $font-size-s;
  line-height: 16px;
  text-align: center;
  cursor: pointer;
  @include transition(opacity, height, width, margin, font-size, visibility);

  &::after {
    content: '';
    position: absolute;
    bottom: -$margin-bottom;
    left: 50%;
    width: 1px;
    height: 0;
    background: $lighter-grey-color;
    opacity: 0;
    @include transition(opacity, height);
  }

  &_collapsed {
    width: 0;
    height: 0;
    margin: 0;
    font-size: 0;
    opacity: 0;
    visibility: hidden;
  }

  &_on {
    margin-bottom: $margin-bottom;

    &::after {
      height: 12px;
      opacity: 1;
    }
  }
}
