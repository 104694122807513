@import './src/config/style/ui-variables';
@import './src/config/style/mixins';

.search {
  position: absolute;
  top: 40px;
  left: 40px;
  z-index: 10;
  min-width: 250px;
  box-shadow: $shadow;

  @include for-mobile {
    top: 15px;
    left: 15px;
    width: calc(100% - 30px);
  }

  &__input {
    box-sizing: border-box;
    width: 100%;
    padding: 10px;
    border: 1px solid $lighter-grey-color;
    border-radius: 5px;
    font-size: $font-size-s;
  }

  &__results {
    overflow-y: scroll;
    max-height: calc(90vh - 110px);
  }
}
