@import './src/config/style/ui-variables';
@import './src/config/style/mixins';

.picture {
  overflow: hidden;
  width: $member-picture-width;
  height: $member-picture-height;
  margin-bottom: 10px;
  border-radius: 50%;
  background-image: url('./images/default.jpg');
  background-position: center;
  background-size: cover;
  @include transition(width, height, margin);

  &_collapsed {
    width: 0;
    height: 0;
    margin: 0;
  }

  &__img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    opacity: 0;
    @include transition(opacity);

    &_loaded {
      opacity: 1;
    }
  }
}
