@import './src/config/style/ui-variables';
@import './src/config/style/mixins';

.details {
  $this: &;

  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  max-width: 250px;
  margin-left: 20px;
  padding: 10px 0 0 20px;
  border-left: 1px solid $light-grey-color;
  @include transition(padding, opacity, margin, border-width, min-width);

  @include for-mobile {
    min-width: 55%;
    margin-left: 10px;
    padding-left: 10px;
  }

  &_collapsed {
    min-width: 0;
    margin: 0;
    padding: 0;
    border-width: 0;
    opacity: 0;
  }

  &__item {
    margin-bottom: 10px;
    @include transition(margin);

    #{$this}_collapsed & {
      margin-bottom: 0;
    }
  }

  &__item-caption {
    margin-bottom: 2px;
    color: $grey-color;
    font-size: $font-size-xs;
    white-space: nowrap;
    @include transition(font-size, margin);

    #{$this}_collapsed & {
      margin: 0;
      font-size: 0;
    }
  }

  &__item-value {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    font-weight: bold;
    font-size: $font-size-s;
    line-height: 1.2;
    @include transition(font-size);

    #{$this}_collapsed & {
      font-size: 0;
    }
  }

  &__item-value-part {
    margin-right: 3px;
    @include transition(margin);

    #{$this}_collapsed & {
      margin: 0;
    }
  }

  &__item-value-addition {
    color: $grey-color;
    font-weight: normal;
    font-size: $font-size-xs;
    @include transition(font-size);

    #{$this}_collapsed & {
      font-size: 0;
    }
  }
}
