@import './src/config/style/ui-variables';
@import './src/config/style/mixins';

.member-info {
  $this: &;

  position: relative;
  box-sizing: border-box;
  margin: 0 20px;
  padding: 20px;
  border-radius: 10px;
  background: #fff;
  box-shadow: $shadow;
  cursor: default;
  @include transition(margin, padding, box-shadow, visibility);

  @include for-mobile {
    max-width: 90vw;
  }

  &_collapsed {
    margin: 0;
    padding: 0;
    visibility: hidden;
  }

  &_details-collapsed {
    padding: 0;
    box-shadow: 0 0 0 transparent;
    cursor: pointer;
    user-select: none;
  }

  &__content {
    display: flex;
  }

  &__history {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__history-content {
    margin: 20px 0 0;
    padding: 0;
    list-style-type: none;
    font-size: $font-size-s;
    font-family: $font-family;
    visibility: visible;
    @include transition(margin, opacity, font-size, visibility);

    &_collapsed {
      margin: 0;
      font-size: 0;
      opacity: 0;
      visibility: hidden;
    }
  }

  &__history-toggle {
    width: 100%;
    height: 30px;
    margin: 10px 0 -15px;
    padding: 0;
    border: 0;
    background: transparent;
    color: $lighter-grey-color;
    font-size: $font-size-xs;
    cursor: pointer;
    @include transition(margin, height, font-size, opacity, visibility);

    &_collapsed {
      height: 0;
      margin: 0;
      font-size: 0;
      opacity: 0;
      visibility: hidden;
    }
  }

  &__close-button {
    position: absolute;
    top: -5px;
    right: -5px;
    @include transition(width, height, opacity, font-size, line-height, visibility);

    #{$this}_details-collapsed & {
      width: 0;
      height: 0;
      font-size: 0;
      line-height: 0;
      opacity: 0;
      visibility: hidden;
    }
  }
}
